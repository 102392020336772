import React from 'react';
import {Description} from "./common";
import styled from "styled-components";
import StyledIcon from "../../../components/common/StyledIcon";

const Header = styled.div`
    margin: 0px;
    font-size: 14px;
    line-height: 1.625;
    font-family: Roboto, Helvetica, Arial, sans-serif !important;
    font-weight: 700;
    letter-spacing: 0.0075em;
    opacity: 1;
    vertical-align: unset;
    text-decoration: none;
    color: rgb(52, 71, 103);
`;

export function NotificationRow(props) {
    return (<>
        <div style={{alignItems: "flex-start", display: 'flex'}}>
            <div style={{display: 'inline-flex', paddingRight: 4}}>
                <div style={{height: "72px"}}>
                    <div style={{
                        width: 32,
                        height: 32,
                        borderRadius: 16,
                        backgroundColor: props.color ? props.color : "rgb(76, 175, 80)",
                        display: 'block',
                        paddingTop: 4,
                        textAlign: "center",
                    }}>
                        <span >
                            <StyledIcon
                                clickable
                                glyph={props.iconName}
                                color="#fff"
                                size="small"
                                style={{fontSize: 12, paddingLeft: 1, display: "table-cell"}}
                            />
                        </span>
                    </div>
                    {!props.isLast && (<span style={{width: 2, height: 40, backgroundColor: "rgb(222, 226, 230)", display: "block", marginLeft: 15}} />)}
                </div>
            </div>
            <div style={{display: 'inline-flex'}}>
                <div>
                    <span style={{height:5, display: "block"}} />
                    <Header>{props.header}</Header>
                   <Description style={{fontSize: 12}}>{props.description}</Description>
                </div>
            </div>
        </div>
    </>)
}
